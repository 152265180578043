<template>

    <div id="app">
        <v-data-table
    :headers="headers"
    :items="list"
    sort-by="code"
    class="elevation-1"
  >
 
    
     <template v-slot:top>
      <v-toolbar
        flat
      >
              <v-toolbar-title>Liste des Data Collections</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
        
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
        </template>
        </v-snackbar>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
             @click="nouveau()" 
            >
              Nouveau
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                  >
                <v-text-field
                      v-model="ref_projet"
                      label="Reférence de souscription*"
                      dense
                      outlined
                      required
                     
                    ></v-text-field>
                   <v-row>
                       <v-col>
                    <v-select
                 :items="listannee"
                 v-model="selectannee"
                 dense
                 item-value="libelle"
                 outlined
                 label="Année de collecte">
                 <template slot="selection" slot-scope="data">
                     {{ data.item.libelle }}
                    </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.libelle }}
                    </template>
                 </v-select>
                       </v-col>
                           <v-col>
                    
                <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="dateFormatted"
                            label="Date"
                            hint="MM/DD/YYYY format"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            @blur="date = parseDate(dateFormatted)"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="date"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                        </v-menu>
                       </v-col>
                   </v-row>
                 <v-textarea
                     v-model="observation"
                    outlined
                    name="input-7-4"
                    label="Observation"
                        >
                 </v-textarea>

                
                </v-form>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog=false"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.souscriptions.indicateurodd`]="{ item }">
       {{ item.souscriptions.indicateurodd.code }} - {{ item.souscriptions.indicateurodd.libelle }}
    </template>

     <template v-slot:[`item.validation`]="{ item }">
      <v-chip
        :color="getColor(item.validation)"
        dark
      >
        {{ getvalidation(item.validation) }}
      </v-chip>
    </template>
    
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        color="green"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        color="red"
        class="mr-2"
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon
        color="bleu"
        class="mr-2"
        small
        @click="chargeItem(item)"
      >
        mdi-database-sync
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Actualiser la liste
      </v-btn>
    </template>
  </v-data-table>
        

    </div>
  
  
</template>

<script>

import api from '../../../../serviceApi/apiService'

export default {
  props: {
    id:"0",
    idcollecte:"",
    libelleindicateur:'',
    annee:'',
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => (
    {
       multiLine: true,
      snackbar: false,
      snackbars:false,
      text: `message`,
      textsnackbar:'messages',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false ,divider: true,width: '50px'},
        { text: 'ID', value: 'id' ,divider: true,width: '10px'},
        { text: 'Date', align: 'start', sortable: false,value: 'dates',divider: true,width: '50px'},
        { text: 'Annee', value: 'annee_collecte' ,divider: true,width: '20px'},
        { text: 'Reference', value: 'souscriptions.code' ,divider: true,width: '100px'},
        { text: 'Indicateur Odd', align: 'start',value: 'souscriptions.indicateurodd',divider: true,width: '250px'},
        { text: 'Etat', align: 'start', sortable: false,value: 'validation',divider: true,width: '10'},
        { text: 'Observation', align: 'start', sortable: false,value: 'observation',divider: true,width: '100'},
      
      ],
      formTitle:'Formulaire de collecte',
       list:[],
       listannee:[],
       listmodalite2:[],
       listmodalite:[],

       idindicateur:'0',
      
      selectcible:'',
      ref_projet:'',
      observation:'',
      valider:'0',
      selectannee:'',
      valid: true,
      dates:'',
      editedIndex: -1,
      textetat:false,
      dateFormatted:null,
      messages:'',
       date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      //dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      
       menu1: false,


    }),

    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },

    watch: {
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },
    },
  
     methods: {
      //=====Affichages  liste des odds==========
          readAll: async function() {
                const data = await api.readAll('souscription/all-request-collecte');
                this.list = data.list;
          },

         readanneeAll: async function() {
                const data = await api.readAll('parametre/all-annee');
                this.listannee = data.list;
          },

         readModaliteAll: async function() {
                const data = await api.readAll('parametre/all-modalite-odd');
                this.listmodalite2 = data.list;
          },
          
          deleteData: async function() {
               let fd=new FormData();
              fd.append('id',this.id_odds);
             const res=await api.createUpdatedata('souscription/delete',fd);   
              this.messagesligne(res);
          },

          createUpdatedata: async function() {
            console.log(this.date);
           console.log(this.ref_projet);
            console.log(this.observation);
            console.log(this.selectannee);
          let fd=new FormData();
          fd.append('ref_projet',this.ref_projet);
          fd.append('observation',this.observation);
          fd.append('annee_collecte',this.selectannee);
          fd.append('dates',this.date);
      
         if(this.textetat){
          
            const res=await api.createUpdatedata('souscription/update-request-collecte/'+this.id,fd);   
            this.text=res.message;
            this.snackbars=true; 
            }else{
            const res=await api.createUpdatedata('souscription/add-request-collecte',fd);    
            console.log(res);
            this.text=res.message;
            this.snackbars=true; 
            }
            
            this.clear();
            this.readAll();
        },
       initialize (){
         this.readAll();
       },
     
        nouveau(){
          this.matricule="";
          this.id_indicateur = "0";
          this.textetat=false;
          this.selectcible='';
          this.selectindicateur='';
 
       },
       Notifications(messages){
      //this.multiLine true,
       this.snackbar=true;
       this.text=messages;
       },
     messagesligne(res){
         if (res.status==200||res.status==401) {
      
          this.textsnackbar= res.message;
          this.timeout= 2000,
          
          this.snackbars= true;
          this.readAll();
          }
      },
    
       clear(){
         this.matricule="";
          this.id_indicateur = "0";
          this.textetat=false;
          this.selectcible='';
          this.selectindicateur='';
          this.textetat=false;
          //this.dialog=false;
       },
      deleteItem(item){
          this.id_odds=item.id;
          this.dialogDelete=true;
         
       },
       editItem(item){
        this.id=item.id;
        this.selectcible;
        this.selectindicateur;
        //this.code = item.code;
        this.textetat=true;
        this.dialog = true;
       },

       chargeItem(item){
         this.idindicateur=item.souscriptions.indicateurodd.id;
        this.$emit('libelle_indicateur',"Indicateur : "+ item.souscriptions.indicateurodd.code+"-"+ item.souscriptions.indicateurodd.libelle);
        this.$emit('reference_projet',"Reference Projet : "+item.souscriptions.code);
        this.$emit('idcollecte',item.id);
        this.$emit('idindicateur',item.souscriptions.indicateurodd.id);
        this.fuseSearchmodalite();
        this.$emit('listemodalite',this.listmodalite);
       // console.log(this.listmodalite);
        
       },
         fuseSearchmodalite(){
            this.listmodalite=[];
            this.listmodalite = this.listmodalite2.filter(this.parcouremodalite);
           },
            parcouremodalite: function (obj) {
            if (obj.id_indicateurodds==this.idindicateur) {
                return true;
            } else {return false;}
            },

      getColor (validation) {
        if (validation ="0") return 'red'
        else if (calories ='1') return 'green'
        else return 'red'
      },

       getvalidation (validation) {
        if (validation ="0") return 'NON'
        else if (calories ='1') return 'OUI'
        else return 'NON'
      },

       deleteItemConfirm(){
        this.deleteData();
        this.readAll();
        this.dialogDelete=false;
       },

      

       save () {
         if(this.ref_projet==''){
           this.text="Veuillez Inserer matricule"
           this.snackbar=true;
           return;
         }

         this.createUpdatedata();
         
       },

         parcoure: function filtrerParID(obj) {
            if (obj.id_cibles==this.selectcible) {
              console.log(obj.libelle);
                return true;
            } else {
               
                return false;
            }
            },
           
        formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    
   
        },
   mounted() {
        this.readAll();
        this.readanneeAll();
        this.readModaliteAll();
      }, 
 
 
}
</script>
