<template>
    <div id="app">
          <v-snackbar
      v-model="snackbar2"
      :multi-line="multiLine"
    >
      {{ text2 }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar2 = false"
        >
          Fermer
        </v-btn>
        </template>
        </v-snackbar>
      <v-row dense>
      <v-col cols="12" md="5">
          <v-card
            color="#385F73"
            dark
          >
            <v-card-title class="text-h5">
                {{ ref_projet }}
            </v-card-title>

            <v-card-subtitle>  {{ textpopindicateur }}</v-card-subtitle>

            <v-card-actions>
              <v-btn text
               @click="Afficher()">
                Actualisez le tableau
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        </v-row>

        <v-data-table
    :headers="headers"
    :items="list"
    sort-by="code"
    class="elevation-1"
  >
 
    
     <template v-slot:top>
      <v-toolbar
        flat
      >
              <v-toolbar-title>Liste des Data Collections</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
        

          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
             @click="nouveau()" 
            >
              Nouveau
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                  >
         
                 <v-select
                 :items="listdepartement"
                 v-model="selectdepartement"
                 dense
                 @change="fuseSearchcommune"
                 item-value="id"
                 outlined
                 label="Département">
                 <template slot="selection" slot-scope="data">
                     {{ data.item.libelle }}
                    </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.libelle }}
                    </template>
                 </v-select>

                 <v-select
                 :items="listcommune"
                 v-model="selectcommune"
                 dense
                 @change="fuseSearcharrondissement"
                 item-value="id"
                 outlined
                 label="Commune *">
                 <template slot="selection" slot-scope="data">
                     {{ data.item.libelle }}
                    </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.libelle }}
                    </template>
                 </v-select>

                 <v-select
                 :items="listarrondissement"
                 v-model="selectarrondissement"
                 dense
                 @change="fuseSearchvillage"
                 item-value="id"
                 outlined
                 label="Arrondissement *">
                 <template slot="selection" slot-scope="data">
                     {{ data.item.libelle }}
                    </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.libelle }}
                    </template>
                 </v-select>

                 <v-select
                 :items="listvillage"
                 v-model="selectvillage"
                 dense
                 item-value="id"
                 outlined
                 label="Village *">
                 <template slot="selection" slot-scope="data">
                     {{ data.item.libelle }}
                    </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.libelle }}
                    </template>
                 </v-select>

                 <v-select
                 :items="listmodalite"
                 v-model="selectmodalite"
                 dense
                 item-value="id"
                 outlined
                 label="Modalité *">
                 <template slot="selection" slot-scope="data">
                     {{ data.item.libelle }}
                    </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.libelle }}
                    </template>
                 </v-select>
                   
                 <v-text-field
                      v-model="libelle"
                      label="La valeur*"
                      dense
                      outlined
                      required
                     
                    ></v-text-field>

                <v-snackbar
                  v-model="snackbar"
                  :multi-line="multiLine"
                >
                  {{ text }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="red"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Fermer
                    </v-btn>
                    </template>
                    </v-snackbar>                
                </v-form>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog=false"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.villages`]="{ item }">
       {{ item.villages.libelle }}
    </template>
    

<template v-slot:[`item.villages.arrondissements`]="{ item }">
       {{ item.villages.arrondissements.libelle }}
    </template>
    <template v-slot:[`item.villages.arrondissements.communes`]="{ item }">
       {{ item.villages.arrondissements.communes.libelle }}
    </template>
    <template v-slot:[`item.villages.arrondissements.communes.departements`]="{ item }">
       {{ item.villages.arrondissements.communes.departements.libelle }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        color="green"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        color="red"
        class="mr-2"
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Actualiser la liste
      </v-btn>
    </template>
  </v-data-table>
        

    </div>
  
   
</template>

<script>

import api from '../../../../serviceApi/apiService'

export default {
  props: {
    textpopindicateur:'',
     textpopref:'00254Odd2022',
     ref_projet:'',
     idcollecte:'',
     idindicateur:'',
     listmodalite:[],
     
     
    informationData: {
      type: Object,
      default: () => {
        //readAll();
      },
    },
  },
  data: () => (
    {
       multiLine: true,
      snackbar: false,
      snackbar2: false,
      snackbars:false,
      text: `message`,
      text2: `message`,
      textsnackbar:'messages',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false ,divider: true,width: '50px'},
        { text: 'ID', value: 'id' ,divider: true,width: '10px'},
        { text: 'Annee', value: 'collectes.annee_collecte' ,divider: true,width: '20px'},
        { text: 'Modalité', value: 'modalites.libelle' ,divider: true,width: '80px'},
        { text: 'valeur', align: 'start',value: 'valeur',divider: true,width: '10px'},
        { text: 'village', align: 'start', sortable: false,value: 'villages',divider: true,width: '150px'},
        { text: 'Arrondissement', align: 'start', sortable: false,value: 'villages.arrondissements',divider: true,width: '100px'},
        { text: 'Commune', align: 'start', sortable: false,value: 'villages.arrondissements.communes',divider: true,width: '100px'},
        { text: 'Departement', align: 'start', sortable: false,value: 'villages.arrondissements.communes.departements',divider: true,width: '100px'},
      
      ],
     // textpopindicateur:'Indicateur',
     
     

      formTitle:'Formulaire de collecte',
       list:[],
       
      
       listdepartement:[],
       listcommune:[],
       listarrondissement:[],
       listvillage:[],
       
       listcommune2:[],
       listarrondissement2:[],
       listvillage2:[],
       
      
      
      selectdepartement:'',
      selectcommune:'',
      selectarrondissement:'',
      selectvillage:'',
      selectmodalite:"",
      idvillage:"0",
      idmodalite:"0",
      libelle:'',
      valid: true,
      editedIndex: -1,
      textetat:false,
      messages:'',
      

    }),

    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },

    watch: {
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },
    },
  
     methods: {
      //=====Affichages  liste des odds==========
          readAll: async function() {
                const data = await api.readAll('souscription/all-request-ligne-collecte/'+this.idcollecte);
                this.list = data.list;
               
          },

         

         readGlobaleAll: async function() {
                const data = await api.readAll('globale-geo/all-globale');
                this.listdepartement = data.departement;
                this.listcommune2 = data.commune;
                this.listarrondissement2 = data.arrondissement;
                this.listvillage2 = data.village;
          },

          
          deleteData: async function() {
               let fd=new FormData();
               fd.append('id',this.id_odds);
               const res=await api.createUpdatedata('souscription/delete',fd);   
              //this.messagesligne(res);
          },

          createUpdatedata: async function() {
            let fd=new FormData();
            fd.append('valeur',this.libelle);
            fd.append('id_modalites',this.selectmodalite);
            fd.append('niveau','village');
            fd.append('id_niveau',this.selectvillage);
            fd.append('id_collectes',this.idcollecte);
      
         if(this.textetat){
          
            const res=await api.createUpdatedata('souscription/update-request-ligne-collecte/'+this.id,fd);   
            this.text=res.message;
            this.snackbar=true; 
            this.dialog=false;
            }else{
            const res=await api.createUpdatedata('souscription/add-request-ligne-collecte',fd);    
            console.log(res);
            this.text=res.message;
            this.snackbar=true; 
            
            }
            
            this.clear();
            this.readAll();
        },
       initialize (){
         this.readAll();
       },
     
        nouveau(){
          if (idcollecte==''||idcollecte==null) {
            this.dialog=false;
            this.text="";
            this.snackbar=true; 
            
          return;}
          this.listcommune=[];
          this.listarrondissement=[];
          this.listvillage=[];
          this.selectdepartement="0",
          this.textetat=false;
         
       },
      
     messagesligne(res){
         if (res.status==200||res.status==401) {
      
          this.text2= res.message;
          this.timeout= 2000,
          
          this.snackbars= true;
          this.readAll();
          }
      },
    
       clear(){
          this.selectvillage='0';
          this.libelle="",
          this.textetat=false;

          this.id_indicateur = "0";
          this.textetat=false;
          this.textetat=false;
          //this.dialog=false;
       },
      deleteItem(item){
          this.id_odds=item.id;
          this.dialogDelete=true;
         
       },
       editItem(item){
        this.id=item.id;
        this.selectdepartement=item.villages.arrondissements.communes.departements.id;
        this.selectcommune=item.villages.arrondissements.communes.id;
        this.selectarrondissement=item.villages.arrondissements.id;
        this.selectvillage=item.villages.id;
        this.fuseSearchcommune();
        this.fuseSearcharrondissement();
        this.fuseSearchvillage();
        this.libelle=item.valeur;
        this.selectmodalite=item.modalites.id;        
        this.textetat=true;
        this.dialog = true;
       },

       deleteItemConfirm(){
        this.deleteData();
        this.readAll();
        this.dialogDelete=false;
       },

      Afficher () {
         this.readAll();
        },
      

       save () {
         if(this.idcollecte==''||this.idcollecte==null){
           this.text2="Veuillez selectionnez le tableau ci-dessous"
           this.snackbar2=true;
           return;
         }

         this.createUpdatedata();
         
       },

          fuseSearchmodalite(){
            this.listmodalite=[];
            this.listmodalite = this.listmodalite2.filter(this.parcouremodalite);
           },

          fuseSearchcommune(){
            this.listcommune=[];
            this.listarrondissement=[];
            this.listvillage=[];
            this.listcommune = this.listcommune2.filter(this.parcourecommune);
           },

           fuseSearcharrondissement(){
            this.listarrondissement=[];
            this.listvillage=[];
            this.listarrondissement = this.listarrondissement2.filter(this.parcourearrondissement);
           },

           fuseSearchvillage(){
            this.listvillage=[];
            this.listvillage = this.listvillage2.filter(this.parcourevillage);
           },

           parcouremodalite: function (obj) {
            if (obj.id_indicateurodds==this.idindicateur) {
                return true;
            } else {return false;}
            },

            parcourecommune: function (obj) {
            if (obj.id_departements==this.selectdepartement) {
                return true;
            } else {return false;}
            },

            parcourearrondissement: function (obj) {
            if (obj.id_communes==this.selectcommune) {
                return true;
            } else {return false;}
            },
            
             parcourevillage: function (obj) {
            if (obj.id_arrondissements==this.selectarrondissement) {
                return true;
            } else {return false;}
            },
           
      
    
   
        },
   mounted() {
        this.readAll();
        this.readGlobaleAll();
        //this.readModaliteAll();
        //props.readAll();
      }, 
 
}
</script>
