<template>
<div id="app">
    <v-row>
        <v-col cols="12">
            <data-collection @reference_projet="valeur_reference=$event" @libelle_indicateur="valeur_indicateur=$event" @idindicateur="valeur_idindicateur=$event" @idcollecte="valeur_idcollecte=$event" @listemodalite="valeur_modalite=$event" />

        </v-col>
        <v-col cols="12">
            <ligne-datacollection :textpopindicateur="valeur_indicateur" :ref_projet="valeur_reference" :idcollecte="valeur_idcollecte" :idindicateur="valeur_idindicateur" :listmodalite="valeur_modalite" />

        </v-col>

    </v-row>

</div>
</template>

<script>
import composantDatacollection from './composant/Datacollectionodd.vue';
import composantLigneDatacollection from './composant/LigneDatacollectionodd.vue';
export default {

    name: 'Collection',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Collection data'
    },
    components: {
        'data-collection': composantDatacollection,
        'ligne-datacollection': composantLigneDatacollection
    },
    props: {
        informationData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({

        valeur_indicateur: '',
        valeur_idindicateur: '',
        valeur_idcollecte: '',
        valeur_reference: '',
        valeur_modalite: [],

    }),

    setup(props) {
        const optionsLocal = ref(JSON.parse(JSON.stringify(props.informationData)))

        const resetForm = () => {
            optionsLocal.value = JSON.parse(JSON.stringify(props.informationData))
        }

        return {
            optionsLocal,
            resetForm
        }
    },
}
</script>
